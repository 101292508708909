import React from "react";
import DraggableTable from "../DraggableTable";

const CurrentSessionActivitiesList = (props) => {
  const serializeItems = (props.currentSessionActivities || []).map(
    (item, ix) => ({
      id: `selected-activity-${ix}`,
      contents: (
        <React.Fragment>
          <td>
            {item.id ? (
              item.swappable &&
              !item.swapped && (
                <i
                  onClick={(e) => props.onSwapClick(e, item.id, item.state_id)}
                  className="btn btn-primary btn-circle btn-sm"
                  title="Swap"
                >
                  <span className="fa fa-flag-checkered" aria-hidden="true" />
                </i>
              )
            ) : (
              <i
                onClick={() => props.removeFromCurrentSession(ix)}
                className="btn btn-primary btn-circle btn-sm"
                title="Remove from current activities"
              >
                <span className="fa fa-trash-o" aria-hidden="true" />
              </i>
            )}
          </td>

          <td>{item.name}</td>
          <td>
            <span class="pull-right" style={{ paddingRight: 32 }}>
              {item.id && item.skippable && !item.state.includes("completed") && (
                <input
                  type="checkbox"
                  checked={item.state.includes("skipped") ? true : false}
                  onChange={() => onCheckToggle(ix)}
                />
              )}
            </span>
          </td>
        </React.Fragment>
      ),
    })
  );

  const onCheckToggle = (toggleIndex) => {
    const result = Array.from(props.currentSessionActivities || []);
    const [removed] = result.splice(toggleIndex, 1);
    const replacement = {
      ...removed,
      state: removed.state.includes("skipped") ? "pending" : "skipped",
    };
    result.splice(toggleIndex, 0, replacement);
    props.updateCurrentSession(result);
  };

  const onReorder = (startIndex, endIndex) => {
    const result = Array.from(props.currentSessionActivities || []);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    props.updateCurrentSession(result);
  };

  return (
    <table
      className="table table-condensed table-striped w-100 d-block d-md-table"
      id="selected_activities_list"
    >
      <thead>
        <tr>
          <th />
          <th>Name</th>
          <th>
            <span class="pull-right" style={{ paddingRight: 16 }}>
              Skip
            </span>
          </th>
        </tr>
      </thead>
      <DraggableTable items={serializeItems} onReorder={onReorder} />
    </table>
  );
};

export default CurrentSessionActivitiesList;
